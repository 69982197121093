body {
  overflow-x: hidden;
  background-color: #ffffff;
}

.main-container {
  margin-left: 40px;
  padding-left: 15px;
}

label {
  @apply text-gray-600;
  @apply text-sm;
}
